import React, { useRef } from "react";
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Colors, Shadows, Sizes, Transitions } from '../global/styles'
import { HeaderContainer } from './base/base'
import deveth_logo from '../assets/images/deveth_logo.png'
import { useDetectOutsideClick } from "./useDetectOutsideClick";

export function TopBar() {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);
  return (
    <Header>
      <HeaderContainer>
        <HeaderNav>
        <ToMain href="/">
            <img src={deveth_logo} style={{width: 40}} />
            <ToMainBottom>
              DEVETH
            </ToMainBottom>
          </ToMain>
          <HeaderNavLinks>
            <HeaderLink exact activeClassName="active-page" to="/balance">
              Balance
            </HeaderLink>
            <HeaderLink exact activeClassName="active-page" to="/transactions">
              Transactions
            </HeaderLink>
            <HeaderLink exact activeClassName="active-page" to="/tokens">
              Tokens
            </HeaderLink>
            <HeaderLink exact activeClassName="active-page" to="/send">
              Send DTH
            </HeaderLink>
            <HeaderLink exact activeClassName="active-page" to="/swap">
              Swap
            </HeaderLink>
          </HeaderNavLinks>
          <HamburgerMenu onClick={onClick} className="menu-trigger">
            <i className="bi bi-list"></i>
          </HamburgerMenu>

          <NavSlide ref={dropdownRef} className={`menu ${isActive ? "active" : "inactive"}`}>
            <NavSlideLink exact activeClassName="active-page" to="/balance">
              Balance
            </NavSlideLink>
            <NavSlideLink exact activeClassName="active-page" to="/transactions">
              Transactions
            </NavSlideLink>
            <NavSlideLink exact activeClassName="active-page" to="/tokens">
              Tokens
            </NavSlideLink>
            <NavSlideLink exact activeClassName="active-page" to="/send">
              Send DTH
            </NavSlideLink>
            <NavSlideLink exact activeClassName="active-page" to="/swap">
              Swap
            </NavSlideLink>
          </NavSlide>
          <Backdrop className={`menu ${isActive ? "active" : "inactive"}`}/>
        </HeaderNav>
      </HeaderContainer>
    </Header>
  )
}

const Backdrop = styled.div`
  &.active {
    background: #3339;
    width: 150%;
    height: 100%;
    z-index: 0;
    position: absolute;
    top: 64px;
    height: 100vh;
    left: -19px;
  }
  &.inactive {
    display: none;
  }
`

const NavSlide = styled.div`
  position: absolute;
  height: 100vh;
  background: #fff;
  width: 300px;
  right: -16px;
  top: 56px;
  z-index: 1;
  display: block;
  &.active {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: transform 300ms ease-in-out 25ms;
  }
  &.inactive {
    transform: translateX(350%);
    -webkit-transform: translateX(350%);
    -moz-transform: translateX(350%);
    -o-transform: translateX(350%);
    -ms-transform: translateX(350%);
    transition: transform 300ms ease-in-out 25ms;
  }
`

const NavSlideLink = styled(NavLink)`
  display: block;
  padding: 20px;
  border-bottom: 1px solid #f1f1f1;

  &.active-page {
    color: #ff6600;
    border-bottom: 3px solid #ff6600;
  }
`

const HamburgerMenu = styled.div`
  display: none;
  position: absolute;
  right: 0;
  i {
    font-size: 35px;
  }
  @media (max-width: 768px) {
    display: block;
  }
`

const Header = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  width: 100%;
  height: ${Sizes.headerHeight};
  background-color: ${Colors.White};
  box-shadow: ${Shadows.main};
  z-index: 100;
`

const HeaderNav = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
`

const ToMain = styled.a`
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  transition: ${Transitions.all};

  &:hover {
    color: ${Colors.Yellow[500]};
  }
`

const ToMainBottom = styled.span`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  align-items: center;
  width: fit-content;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
`

const HeaderNavLinks = styled.div`
  display: grid;
  position: absolute;
  left: 50%;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 20px;
  align-items: center;
  height: 100%;
  transform: translateX(-50%);
  @media (max-width: 768px) {
    display: none;
  }
`

const HeaderLink = styled(NavLink)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  transition: ${Transitions.all};
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px);
    height: 4px;
    background-color: #f3841d;
    transform: scaleX(0);
    transform-origin: 50% 50%;
    transition: ${Transitions.all};
  }

  &:hover {
    color: ${Colors.Yellow[500]};

    &:after {
      transform: scaleX(1);
    }
  }
  &.active-page {
    &:after {
      transform: scaleX(1);
    }
  }
`
