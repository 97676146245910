import React from 'react'
import styled from 'styled-components'
import { Container, ContentBlock, MainContent, Section, SectionRow } from '../components/base/base'
import { TokensList } from '../components/TokensList/TokensList'
import { Title } from '../typography/Title'

import { AccountButton } from '../components/account/AccountButton'

export function Tokens() {
  return (
    <MainContent>
      <Container>
        <Section>
        <nav className="navbar bg-transparent">
            <div className="container-fluid d-block">
              <div className="float-end">
                <AccountButton />
              </div>
            </div>
          </nav>
          <div className="container">
            <TokensList />
          </div>
        </Section>
      </Container>
    </MainContent>
  )
}

const TokensContentBlock = styled(ContentBlock)`
  padding: 16px 32px;
`
