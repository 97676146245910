import React, { useEffect, useState } from 'react'
import { formatEther } from '@ethersproject/units'
import { BigNumber } from 'ethers'
import { ContentBlock } from '../base/base'
import { TextBold } from '../../typography/Text'
import { Colors, BorderRad } from '../../global/styles'
import styled from 'styled-components'
import { useEtherBalance, useEthers } from '@usedapp/core/dist/esm/src'
import { Button } from '../base/Button'
import { useSendTransaction } from '@usedapp/core/dist/esm/src'
import { utils } from 'ethers'
import { StatusAnimation } from '../Swap/SwapForm'
import devETH from '../../assets/images/devETH.png'
import deveth_pool from '../../assets/images/mining_pool.png'
import image_poser from '../../assets/images/image_poser.png'

const formatter = new Intl.NumberFormat('en-us', {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
})

const formatBalance = (balance: BigNumber | undefined) =>
  formatter.format(parseFloat(formatEther(balance ?? BigNumber.from('0'))))

const InputComponent = () => {
  const { account } = useEthers()

  const [amount, setAmount] = useState('0')
  const [address, setAddress] = useState('')
  const [disabled, setDisabled] = useState(false)

  const { sendTransaction, state } = useSendTransaction({ transactionName: 'Send Ethereum' })

  const handleClick = () => {
    setDisabled(true)
    sendTransaction({ to: address, value: utils.parseEther(amount) })
  }

  useEffect(() => {
    if (state.status != 'Mining') {
      setDisabled(false)
      setAmount('0')
      setAddress('')
    }
  }, [state])

  return (
    <>
      <InputRow>
        <div className="form-group" style={{ margin: "0 0 30px"}}>
          <Label htmlFor={'EthInput'}>
            How much?
          </Label>
          <Input
            id={`EthInput`}
            type="number"
            step="0.01"
            value={amount}
            onChange={(e) => setAmount(e.currentTarget.value)}
            min="0"
            disabled={disabled}
          />
        </div>
        <div className="form-group" style={{ margin: "0 0 30px"}}>
          <Label htmlFor={'AddressInput'}>
            To whom?
          </Label>
          <AddressInput
            id={`AddressInput`}
            type="text"
            value={address}
            onChange={(e) => setAddress(e.currentTarget.value)}
            disabled={disabled}
          />
        </div>
        <SmallButton disabled={!account || disabled} onClick={handleClick}>
          Send
        </SmallButton>
      </InputRow>
      <StatusAnimation transaction={state} />
    </>
  )
}

export const SendEthForm = () => {
  const { account, chainId } = useEthers()
  const balance = useEtherBalance(account)
  return (
    <>
      { account ? (
          <div className="col-12">
            <div className="card shadow bg-body rounded">
              <div className="card-header">
                Transfer
              <BalanceWrapper>Your dTH balance: {formatBalance(balance)}</BalanceWrapper>
              </div>
              <div className="card-body">
              <InputComponent />
              </div>
            </div>
          </div>
      ) : 
        <>
          <WhyDth className=" shadow rounded" style={{padding: 20}}>
            <p>devETH is exactly like Ethereum. Ethereum has become so big and expensive making it hard for new developers to join in and learn. On devETH you can participate in every aspect of the chain, even the mining! If you mess up, no problem. devETH is cheap and always will be.</p>

            <p>While devETH is cheap, it is not free. There are miners making sure the chain is safe and secure. This costs money. So naturally there will be a price for devETH. The aim is to peg the value of 1 devETH (dTH) at 0.001Ξ.</p>

            <p>devETH does not intend to run a pre-mined crowdsale. We also do not intend to pay exchanges to list us. We trust exchanges will do the listing if we provide enough value to the Ethereum ecosystem.</p>

            <p>If you have Ethereum as of block 11818959, you already have devETH. It is 100% compatible, and many existing contracts of the Ethereum mainnet are already available on devETH.</p>

            <p>If you are a miner, have a look at our Mining Guide and see how you can start mining devETH. If you just want to set up a wallet, see our Wallet Setup. You can also join us on our official Discordy Server</p>
          </WhyDth>
          <div className="card flex-row shadow p-3 mb-5 bg-white rounded" style={{margin: '20px 0', padding: 20}}>
            <div className="card-body">
              <h4 className="card-title h5 h4-sm" style={{margin: '0 0 30px 0'}}>HOW TO GET dTH</h4>
              <div className="row" style={{textAlign: 'center'}}>
              <div className="col-12 col-md-4">
                <a href="https://discord.gg/pWzZKsbv" target="_blank">
                  <img src={devETH} style={{width: 100}}/>
                  <p>Join our Discord Channel</p>
                </a>
              </div>
              <div className="col-12 col-md-4">
                <a href="https://cethpool.vkoskiv.com/#/" target="_blank">
                  <img src={deveth_pool} style={{width: 100}}/>
                  <p>Mining in our Pools</p>
                </a>
              </div>
              <div className="col-12 col-md-4">
                <a href="https://www.unklick.de" target="_blank">
                  <img src={image_poser} style={{width: 100}}/>
                  <p>Posting Image poser to earn some dTH</p>
                </a>
              </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

const WhyDth = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  p {
    margin: 10px 0;
  }
`

const Label = styled.label`
  font-weight: 700;
  display: block;
`

const BalanceWrapper = styled.div`
  color: ${Colors.Gray['600']};
  font-size: 14px;
`

const Input = styled.input`
  height: 40px;
  width: 120px;
  padding: 0 24px 0 24px;
  border: 1px;
  display: block;
  border-radius: ${BorderRad.m};
  -moz-appearance: textfield;

  box-shadow: inset 0 0 0 1px #000;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
  }

  &:focus {
    outline: transparent auto 1px;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px ${Colors.Black['900']} !important;
  }
`

const AddressInput = styled(Input)`
  width: 100%;
  padding: 0 0 0 38px;
`

const InputRow = styled.div`
  height: 44px;
  display: contents;
  margin: 0 auto;
  color: ${Colors.Gray['600']};
  align-items: center;
  border-radius: ${BorderRad.m};
`

const FormTicker = styled.div`
  padding: 0 8px;
`

const SmallButton = styled(Button)`
  display: flex;
  justify-content: center;
  min-width: 95px;
  height: 100%;
  padding: 8px 24px;

  &:disabled {
    color: ${Colors.Gray['600']};
    cursor: unset;
  }

  &:disabled:hover,
  &:disabled:focus {
    background-color: unset;
    color: unset;
  }
`
