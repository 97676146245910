import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers'
import React from 'react'
import { useContractFunction, useEtherBalance, useEthers, useTokenBalance } from '@usedapp/core/dist/esm/src'

import { SwapForm } from './SwapForm'

import WethAbi from '../../abi/Weth10.json'

const wethInterface = new utils.Interface(WethAbi)
const wethContractAddress = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
const contract = new Contract(wethContractAddress, wethInterface)

export const DepositEth = () => {
  const { account } = useEthers()
  const etherBalance = useEtherBalance(account)

  const { state, send } = useContractFunction(contract, 'deposit', { transactionName: 'Wrap' })

  const depositEther = (etherAmount: string) => {
    send({ value: utils.parseEther(etherAmount) })
  }

  return (
    <>
      { account && (
          <SwapForm balance={etherBalance} send={depositEther} title="Wrap dTH" ticker="dTH" transaction={state} />
      )}
    </>
  )
}

export const WithdrawEth = () => {
  const { account } = useEthers()
  const wethBalance = useTokenBalance(wethContractAddress, account)

  const { state, send } = useContractFunction(contract, 'withdraw', { transactionName: 'Unwrap' })

  const withdrawEther = (wethAmount: string) => {
    send(utils.parseEther(wethAmount))
  }

  return (
    <>
      { account && (
          <SwapForm
          balance={wethBalance}
          send={withdrawEther}
          title="Unwrap WETH"
          ticker="WETH"
          transaction={state}
        />
      )}
    </>
  )
}
