import React from 'react'
import { formatEther } from '@ethersproject/units'
import {useEtherBalance, useEthers,} from '@usedapp/core/dist/esm/src'
import { ContentRow} from '../base/base'
import { Label } from '../../typography/Label'
import { TextInline } from '../../typography/Text'
import styled from 'styled-components'
import { TextBold } from '../../typography/Text'
import { ContentBlock } from '../base/base'
import { Colors } from '../../global/styles'
import { NavLink } from 'react-router-dom'
import devETH from '../../assets/images/devETH.png'


const robohashAvatars = require("robohash-avatars");
const STAKING_CONTRACT = '0x00000000219ab540356cBB839Cbe05303d7705Fa'


export const DTH2Balance = () => {
  const { account } = useEthers()
  const stakingBalance = useEtherBalance(STAKING_CONTRACT)

  return (
    <SmallContentBlock>
      <TitleRow>
        <CellTitle>dTH2 staking contract holds</CellTitle>
      </TitleRow>
          {stakingBalance && (
              <ContentRow style={{padding: 20, textAlign: 'center'}}>
                  <TextInline>{formatEther(stakingBalance)}</TextInline>{' '}
                  <Label>dTH</Label>
              </ContentRow>
          )}
    </SmallContentBlock>
  )
}

export const UserBalance = () => {
  const { account } = useEthers()
  const userBalance = useEtherBalance(account)
  const avatarURL = robohashAvatars.generateAvatar({   
    username: account, 
    background: robohashAvatars.BackgroundSets.RandomBackground1,
    characters: robohashAvatars.CharacterSets.Robot,
    height: 100,
    width: 100
  });


  return (
      <>
        {account ? (
            <div className="col-12 col-md-6">
                <div className="card">
                  <div className="card-header">
                    Current Balance
                  </div>
                  <div className="card-body">
                    <img src={avatarURL} style={{
                    borderStyle: 'none',
                    display: 'block',
                    margin: '0 auto 20px',
                    borderRadius: '50%'}}/>
                    <p className="text-center">{account}</p>
                    {userBalance && (
                        <ContentRow style={{padding: '0 20px 20px', textAlign: 'center'}}>
                          <span style={{
                            maxWidth: 150,
                            fontSize: 26,
                            fontWeight: 600,
                            color: '#17935d'}} className="d-inline-block text-truncate">{formatEther(userBalance)}</span> <CurrencySymbol>dTH</CurrencySymbol>
                          <HeaderLink activeClassName="active-page" to="/send">
                            {' '}
                            Send DTH{' '}
                          </HeaderLink>
                        </ContentRow>
                    )}
                  </div>
                </div>
            </div>
          ) : 
          <PlaceholderDiv>
            <img src={devETH} style={{maxWidth: 200, marginBottom: 60}}/>
            <h1>devETH</h1>
<h1 style={{fontWeight: 200}}>You like Ethereum? You're a developer? You will love devETH. It's exactly like normal Ethereum. Just much cheaper!</h1>
          </PlaceholderDiv> }
      </>

  )
}

const PlaceholderDiv = styled.div`
  text-align: center;
  img {
    margin-top: 60px;
  }

  h1 {
    color: #fff;
    font-weight: 500;
  }
`

const HeaderLink = styled(NavLink)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.1em;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 21px;
  background: #583d72;
  margin-top: 30px;
  color: #fff !important;
  border-radius: 22px;

  &:hover {
    color: ${Colors.Yellow[500]};

    &:after {
      transform: scaleX(1);
    }
  }
  &.active-page {
    &:after {
      transform: scaleX(1);
    }
  }
`

const Account = styled.span`
    padding: 5px;
    color: #333;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    display: inline-block;
    text-decoration: underline;
`

const CurrencySymbol = styled.span`
  display: inline-block;
  font-size: 30px;
  line-height: unset;
  overflow: hidden;
  font-weight: 600;
`

const Truncate = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  font-size: 30px;
  font-weight: 600;
  color: #17955b;
`

const CellTitle = styled(TextBold)`
  font-size: 18px;
`
const TitleRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: ${Colors.Gray['300']} 1px solid;
  padding: 16px;
  background: #f1f1f1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`
const SmallContentBlock = styled(ContentBlock)`
  padding: 0;
`
