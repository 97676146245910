import React, { useEffect, useState } from 'react'
import { useEthers, shortenAddress } from '@usedapp/core/dist/esm/src'
import { Button } from '../base/Button'
import { Colors } from '../../global/styles'
import styled from 'styled-components'
import swal from 'sweetalert'

import { AccountModal } from './AccountModal'

export const AccountButton = () => {
  const { account, deactivate, activateBrowserWallet } = useEthers()
  const [showModal, setShowModal] = useState(false)

  const [activateError, setActivateError] = useState('')
  const { error } = useEthers()
  useEffect(() => {
    if (error) {
      setActivateError(error.message)
      let div: any = document.createElement("div");
      div.innerHTML = error.message;
      console.log('active', activateError)
      swal({
        title: 'DEVETH - WARNING',
        content: div,
        icon: 'warning',
      }).then(() => window.location.reload())
    }
  }, [error])

  const activate = async () => {
    setActivateError('')
    activateBrowserWallet()
  }

  return (
    <Account>
      {showModal && <AccountModal setShowModal={setShowModal} />}
      {account ? (
        <Account>
          <AccountLabel onClick={() => setShowModal(!showModal)}>{shortenAddress(account)}</AccountLabel>
          <LoginButton onClick={() => deactivate()}>Disconnect</LoginButton>
        </Account>
      ) : (
        <LoginButton onClick={activate}>Connect</LoginButton>
      )}
    </Account>
  )
}

const Account = styled.div`
  display: flex;
  align-items: center;
`

const LoginButton = styled(Button)`
  float: right;
  background-color: rgb(88 61 114);
  border: 0;
  color: #fff;
`

const AccountLabel = styled.div`
  margin-left: 0 !important;
  float: left;
  margin: 10px;
  margin-right: -40px;
  padding: 7px;
  padding-right: 40px;
  display: flex;
  color: #fff;
  background-color: #f3841d7a;
  border-radius: 20px;
  &:hover {
    background-color: black;
    color: ${Colors.Yellow[100]};
    cursor: pointer;
  }
`
