import React from 'react'
import { formatUnits } from '@ethersproject/units'
import uniswapToken from '@uniswap/default-token-list'
import { ChainId, ERC20Interface, useContractCalls, useEthers } from '@usedapp/core/dist/esm/src'
import styled from 'styled-components'
import { Colors } from '../../global/styles'
import { TextBold } from '../../typography/Text'
import { TokenIcon } from './TokenIcon'
import deveth_swap from '../../assets/images/deveth_swap.png'
function getTokenList(chainId?: ChainId) {
  return uniswapToken.tokens.filter((token) => token.chainId == chainId)
}

function useTokensBalance(tokenList: any[], account?: string | null) {

  return useContractCalls(
    tokenList && account
      ? tokenList.map((token: any) => ({
          abi: ERC20Interface,
          address: token.address,
          method: 'balanceOf',
          args: [account],
        }))
      : []
  )
}

export function TokensList() {
  const { chainId, account } = useEthers()
  const tokenList = getTokenList(chainId)
  const balances: any = useTokensBalance(tokenList, account)


  return (
    <>
      { account ? (
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="card-header">
                Assets List
              </div>
              <div className="card-body">
                <ol className="list-group list-group-flush">
                  { balances != 0 ? tokenList &&
                    tokenList.map((token, idx) => (
                      balances?.[idx] && formatUnits(balances[idx]![0], token.decimals) !== '0.0' ? 
                    <li key={token.address} className="list-group-item d-flex justify-content-between align-items-start">
                      <TokenIcon src={token.logoURI} alt={`${token.symbol} logo`} />
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{token.name}</div>
                        {  balances?.[idx] && <TokenBalance>{formatUnits(balances[idx]![0], token.decimals)}</TokenBalance>}
                      </div>
                    </li>
                    : ''
                    )): <EmptyPage>You have No Assets yet!</EmptyPage>}
                </ol>
              </div>
            </div>
          </div>
      ):         <>
      <div className="card mb-3 shadow">
        <div className="row no-gutters">
          <ImageContainer className="col-md-4">
            <img className="card-img-left example-card-img-responsive" src={deveth_swap} />
          </ImageContainer>
          <div className="col-md-8">
            <div className="card-body">
            <h4 className="card-title h5 h4-sm">MORE THAN 50 TOKENS</h4>
            <p className="card-text">it is more than 50 Tokens that run in our DEX services. try out our <a href="https://www.devethswap.de" target="_blank" style={{background: '#ff6600', padding: 3, color: '#fff', borderRadius: 3}}>devethswap</a></p>
            </div>
          </div>
        </div>
      </div>
    </>}
    </>
  )
}


const ImageContainer = styled.div`
  // background: #f1f1f1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 20px;
  text-align: center;
  img {
    max-width: 100px;
  }
`

const EmptyPage = styled.span`
  text-align: center;
  margin: 30px 0;
  font-size: 27px;
  color: #583d72;
`

const CellTitle = styled(TextBold)`
  font-size: 18px;
`

const LabelRow = styled.div`
  display: flex;
  margin: 32px 0 24px 0;
`

const Label = styled.label`
  font-weight: 700;
`

const TitleRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: ${Colors.Gray['300']} 1px solid;
  padding: 16px;
  background: #f1f1f1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`


const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TokenItem = styled.li`
  max-width: 50px;
  display: grid;
  grid-template-areas:
    'icon name balance'
    'icon ticker balance';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  & + & {
    border-top: 1px solid ${Colors.Black[200]};
  }
`

const TokenIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: icon;
  width: 40px;
  height: 40px;
  padding: 1px;
  font-size: 36px;
  line-height: 36px;
`

const TokenName = styled(TextBold)`
  grid-area: name;
`

const TokenTicker = styled(TextBold)`
  grid-area: ticker;
  color: #ff6600;
`

const TokenBalance = styled(TextBold)`
  grid-area: balance;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  color: #333;
`
