import React from 'react'
import { Container, MainContent, Section, SectionRow } from '../components/base/base'

import {TransactionsList } from '../components/Swap/History'
import { Title } from '../typography/Title'
import { AccountButton } from '../components/account/AccountButton'

export function Transactions() {
  return (
    <MainContent>
      <Container>
        <Section>
        <nav className="navbar bg-transparent">
            <div className="container-fluid d-block">
              <div className="float-end">
                <AccountButton />
              </div>
            </div>
          </nav>
          <div className="container">
            <TransactionsList />
          </div>
        </Section>
      </Container>
    </MainContent>
  )
}