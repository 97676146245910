import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import { Page } from './components/base/base'
import { TopBar } from './components/TopBar'
import { GlobalStyle } from './global/GlobalStyle'
import { Balance } from './pages/Balance'
import { Transactions } from './pages/Transactions'
import { Tokens } from './pages/Tokens'
import { Swap } from './pages/Swap'
import { SendEtherPage } from './pages/SendEtherPage'

export function App() {
  return (
    <Page>
      <GlobalStyle />
      <HashRouter basename="/">
        <TopBar />
        <Switch>
          <Route exact path="/balance" component={Balance} />
          <Route exact path="/transactions" component={Transactions} />
          <Route exact path="/tokens" component={Tokens} />
          <Route exact path="/send" component={SendEtherPage} />
          <Route exact path="/swap" component={Swap} />
          <Redirect exact from="/" to="/balance" />
        </Switch>
      </HashRouter>
    </Page>
  )
}
