import React from 'react'
import { MainContent, Container, Section, SectionRow } from '../components/base/base'
import { Title } from '../typography/Title'
import { NotificationsList } from '../components/Swap/History'
import styled from 'styled-components'

import { AccountButton } from '../components/account/AccountButton'

import { SendEthForm } from '../components/SendEthForm/SendEthForm'

export const SendEtherPage = () => {
  return (
    <MainContent>
      <Container>
        <Section>
        <nav className="navbar bg-transparent">
            <div className="container-fluid d-block">
              <div className="float-end">
                <AccountButton />
              </div>
            </div>
          </nav>
          <div className="container">
            <SendEthForm />
            <NotificationsList />
          </div>
        </Section>
      </Container>
    </MainContent>
  )
}

const TableGrid = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`
