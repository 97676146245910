import React, { useEffect, useState } from 'react'
import { Container, MainContent, Section, SectionRow } from '../components/base/base'
import { useEthers } from '@usedapp/core/dist/esm/src'
import { Title } from '../typography/Title'
import styled from 'styled-components'

import { AccountButton } from '../components/account/AccountButton'
import { UserBalance } from '../components/balance/UserBalance'

export function Balance() {
  return (
    <MainContent>
      <Container>
        <Section>
          <nav className="navbar bg-transparent">
            <div className="container-fluid d-block">
              <div className="float-end">
                <AccountButton />
              </div>
            </div>
          </nav>
          <div className="container">
            <UserBalance/>
          </div>
        </Section>
      </Container>
    </MainContent>
  )
}

const ErrorWrapper = styled.div`
  color: red;
  margin-right: 40px;
  margin-left: 40px;
  overflow: auto;
`

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`