import React from 'react'
import styled from 'styled-components'
import { Container, MainContent, Section, SectionRow } from '../components/base/base'

import { DepositEth, WithdrawEth } from '../components/Swap/Forms'
import { NotificationsList } from '../components/Swap/History'
import { Title } from '../typography/Title'

import { AccountButton } from '../components/account/AccountButton'
import { useEthers, useTransactionsContext } from '@usedapp/core/dist/esm/src'
import deveth_swap from '../assets/images/deveth_swap.png'

export function Swap() {
  const { account } = useEthers()
  return (
    <MainContent>
      <Container>
        <Section>
        <nav className="navbar bg-transparent">
            <div className="container-fluid d-block">
              <div className="float-end">
                <AccountButton />
              </div>
            </div>
          </nav>
          <div className="container">
          <div className="row">
            { account ? 
              <>
                <DepositEth />
                <WithdrawEth /> 
              </> :

            <div className="card mb-3 shadow">
            <div className="row no-gutters">
              <ImageContainer className="col-md-4">
                <img className="card-img-left example-card-img-responsive" src={deveth_swap} />
              </ImageContainer>
              <div className="col-md-8">
                <div className="card-body">
                <h4 className="card-title h5 h4-sm">DEX devethswap</h4>
                <p className="card-text">We provide you more than 50 Tokens you can play with. for more visit our <a href="https://www.devethswap.de" style={{background: '#ff6600', color: '#fff', padding: 3, borderRadius: 3 }}>devethswap</a> page.
              </p>
                </div>
              </div>
            </div>
            </div>
            }
          </div>
          <NotificationsList />
          </div>
        </Section>
      </Container>
    </MainContent>
  )
}


const ImageContainer = styled.div`
  // background: #f1f1f1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 20px;
  text-align: center;
  img {
    max-width: 100px;
  }
`

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`
